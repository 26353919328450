import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const styles = () => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  gridList: {
    width: '100%'
  }
});

const calcCols = width => {
  if (isWidthDown('xs', width)) {
    return 1;
  }

  if (isWidthDown('sm', width)) {
    return 3;
  }

  return 4;
};

const ImageGridSection = ({ classes, images, width }) => {
  const cols = calcCols(width);

  return (
    <div className={classes.root}>
      <GridList cellHeight={240} className={classes.gridList} cols={cols}>
        {images &&
          images.map(({ title, image }) => (
            <GridListTile key={uuid.v4()}>
              <img src={image.url} alt={title} />
              <GridListTileBar title={title} />
            </GridListTile>
          ))}
      </GridList>
    </div>
  );
};

ImageGridSection.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
    gridList: PropTypes.string.isRequired
  }).isRequired,
  width: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      copyright: PropTypes.string
    })
  ).isRequired
};

export default withWidth()(withStyles(styles)(ImageGridSection));
