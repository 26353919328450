import React from 'react';
import PropTypes from 'prop-types';
import { navigate, graphql } from 'gatsby';

import Page from '../layout/Page';
import Container from '../layout/Container';
import Hero from '../components/hero/Hero';
import TextWithImageSection from '../components/product/text-with-image-section/TextWithImageSection';
import BenefitsSection from '../components/product/benefits-section/BenefitsSection';
import ImageGridSection from '../components/product/image-grid-section/ImageGridSection';
import InformationSection from '../components/product/information-section/InformationSection';
import SectionHeading from '../components/section/SectionHeading';
import Cta from '../components/cta/Cta';

const Product = ({ data, location }) => {
  const { prismicProducts } = data;
  if (!prismicProducts) {
    if (typeof window !== 'undefined') {
      navigate('/');
    }
    console.error('Page has not been translated.');
    return null;
  }

  const { data: prismicData } = prismicProducts;
  const {
    page_title,
    page_description,
    page_keywords,
    page_image,
    hero_title,
    hero_subtitle,
    hero_image,
    hero_button,
    about_title,
    about_subtitle,
    about_description,
    about_license,
    about_image,
    about_button,
    features_title,
    features,
    media_title,
    media_subtitle,
    media,
    files_title,
    files_subtitle,
    files,
    cta_title,
    cta_subtitle,
    cta_button
  } = prismicData;

  return (
    <Page
      seo={{
        page_title,
        page_description,
        page_keywords,
        page_image,
        pathname: location.pathname
      }}
    >
      <Container size={50} isCenter>
        <Hero
          title={hero_title}
          subtitle={hero_subtitle}
          image={hero_image}
          button={hero_button[0]}
        />
      </Container>

      <Container size={66} isCenter>
        <TextWithImageSection
          title={about_title}
          subtitle={about_subtitle}
          description={about_description.text}
          license={about_license}
          image={about_image}
          button={about_button[0]}
        />
      </Container>

      {/* Benefits  */}
      {features_title && (
        <Container>
          <SectionHeading title={features_title} />
        </Container>
      )}

      {features && features.length > 0 && (
        <Container size={50} isCenter>
          <BenefitsSection benefits={features} />
        </Container>
      )}
      {/* /Benefits */}

      {/* Image Grid */}
      {media_title && (
        <Container>
          <SectionHeading title={media_title} subtitle={media_subtitle} />
        </Container>
      )}

      {media && (
        <Container isCenter>
          <ImageGridSection images={media} />
        </Container>
      )}
      {/* /Image Grid */}

      {files_title && (
        <Container>
          <SectionHeading title={files_title} subtitle={files_subtitle} />
        </Container>
      )}

      {files && files.length > 0 && (
        <Container size={33} isCenter>
          <InformationSection leaflets={files} />
        </Container>
      )}

      <Container
        size={50}
        background="url(/images/footer_background.png) bottom/cover no-repeat"
      />

      <Container background="#1976d2">
        <Cta title={cta_title} subtitle={cta_subtitle} button={cta_button[0]} />
      </Container>
    </Page>
  );
};

Product.propTypes = {
  data: PropTypes.shape({
    prismicProducts: PropTypes.shape({
      data: PropTypes.shape().isRequired
    }).isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export const query = graphql`
  query($locale: String!, $slug: String!) {
    prismicProducts(lang: { eq: $locale }, uid: { eq: $slug }) {
      uid
      data {
        page_title
        page_description
        page_keywords
        page_image {
          alt
          copyright
          url
        }
        hero_title
        hero_subtitle
        hero_image {
          alt
          copyright
          url
        }
        hero_button {
          title
          to
        }
        about_title
        about_subtitle
        about_description {
          html
          text
        }
        about_license
        about_image {
          alt
          copyright
          url
        }
        about_button {
          title
          to
        }
        features_title
        features {
          title
          description
          image {
            alt
            copyright
            url
          }
        }
        media_title
        media_subtitle
        media {
          title
          image {
            alt
            copyright
            url
          }
        }
        files_title
        files_subtitle
        files {
          title
          file {
            link_type
            name
            kind
            url
            size
            target
          }
        }
        cta_title
        cta_subtitle
        cta_button {
          title
          to
        }
      }
    }
  }
`;

export default Product;
