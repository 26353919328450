import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Link from '../../link/Link';

const styles = () => ({
  media: {
    'max-width': '100%',
    display: 'flex',
    boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.3)'
  }
});

const ProductSection = ({
  classes,
  title,
  subtitle,
  license,
  description,
  button,
  image
}) => (
  <Grid
    container
    item
    direction="row"
    justify="space-between"
    alignItems="center"
  >
    <Grid item xs={12} md={5}>
      <img src={image.url} alt={image.alt} className={classes.media} />
    </Grid>

    <Grid item xs={12} md={6}>
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>

      {subtitle && (
        <Typography variant="h6" component="h2" gutterBottom>
          {subtitle}
        </Typography>
      )}
      {license && (
        <Typography variant="overline" component="h2" gutterBottom>
          Product license: {license}
        </Typography>
      )}

      <Typography variant="body1" component="h2" gutterBottom>
        {description}
      </Typography>

      {button.title && button.to && (
        <Link to={button.to}>
          <Button variant="contained" color="primary">
            {button.title}
          </Button>
        </Link>
      )}
    </Grid>
  </Grid>
);

ProductSection.propTypes = {
  classes: PropTypes.shape({
    media: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    copyright: PropTypes.string
  }).isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired,
  license: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default withStyles(styles)(ProductSection);
