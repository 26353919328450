import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Link from '../../link/Link';

const InformationSection = ({ leaflets }) => (
  <Box py={2}>
    <Grid
      container
      item
      direction="row"
      justify="space-evenly"
      alignItems="stretch"
    >
      {leaflets &&
        leaflets.map(leaflet => (
          <Grid
            container
            item
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={12}
            md={2}
            key={uuid.v4()}
            spacing={2}
          >
            <Grid item>
              <Link to={leaflet.file.url}>
                <img
                  src="/images/product/pdf.gif"
                  alt={leaflet.title || leaflet.file_title}
                />
              </Link>
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                component="h2"
                align="center"
                gutterBottom
              >
                {leaflet.title || leaflet.file_title}
              </Typography>
            </Grid>
          </Grid>
        ))}
    </Grid>
  </Box>
);

InformationSection.propTypes = {
  leaflets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      file_title: PropTypes.string,
      file: PropTypes.shape({
        url: PropTypes.string.isRequired
      })
    })
  ).isRequired
};
export default InformationSection;
