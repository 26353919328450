import uuid from 'uuid';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  media: {
    'max-width': '100%',
    display: 'flex'
  }
});

const BenefitsSection = ({ classes, benefits }) => (
  <Grid
    container
    direction="row"
    justify="space-evenly"
    alignItems="flex-start"
  >
    {benefits &&
      benefits.map(benefit => (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          xs={12}
          md={3}
          key={uuid.v4()}
        >
          <Grid item xs={8}>
            <img
              className={classes.media}
              src={benefit.image.url}
              alt={benefit.image.alt}
            />
          </Grid>
          <Typography variant="h5" component="h3" align="center" gutterBottom>
            {benefit.title}
          </Typography>
          <Typography
            variant="body1"
            component="h4"
            align="center"
            gutterBottom
          >
            {benefit.description}
          </Typography>
        </Grid>
      ))}
  </Grid>
);
BenefitsSection.propTypes = {
  classes: PropTypes.shape({
    media: PropTypes.string.isRequired
  }).isRequired,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        copyright: PropTypes.string
      }).isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired
};

export default withStyles(styles)(BenefitsSection);
